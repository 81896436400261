<template>
    <div class="choose-building page">
        <ChooseBuilding />  
        <div class="bgr-img animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')">
            <img src="@/assets/images/bgr/choose_building-mobile.jpg" />
        </div>
    </div>
</template>

<script>
import ChooseBuilding from '@/components/ChooseBuilding.vue'

export default {
    components:{
        ChooseBuilding
    }    
}
</script>